import ru from "vee-validate/dist/locale/ru";
import VeeValidate, { Validator } from "vee-validate";
import Vue from "vue";

Vue.use(VeeValidate);
Validator.localize("ru", ru);
Validator.extend(
  "isDateGte",
  (value, [otherValue]) => {
    return value >= otherValue;
  },
  {
    hasTarget: true,
    isDate: true,
  }
);
Validator.extend(
  "isSetOther",
  (value, [otherValue]) => {
    return {
      valid: value.length >= 3,
      data: {
        required: !otherValue,
      },
    };
  },
  {
    hasTarget: true,
    computesRequired: true,
  }
);
Validator.extend("strongPassword", {
  getMessage: (field) =>
    `Пароль должен содержать: 1 заглавную букву, 1 прописную, 1 цифру и один из символов (!@#$%^&*)`,
  validate: (value) => {
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})"
    );
    return strongRegex.test(value);
  },
});
