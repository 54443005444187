import { Entity, MODULE } from "./const";
import { SET_ENTITY } from "../../mutation-types";
import { Resource } from "@/common/const/common";
import { IDENTITY_ERROR } from "@/common/const/message";

const module = MODULE;

export default {
  async setAuth({ dispatch, commit }) {
    const token = this.$jwt.getToken();
    if (token) {
      this.$api.auth.setAuthHeader(token);
      const value = await dispatch("getMe");
      if (value) {
        commit(
          SET_ENTITY,
          { module, entity: Entity.IS_AUTHENTICATED, value },
          { root: true }
        );
        return true;
      }
    }
    return false;
  },

  async login({ commit, dispatch }, credentials) {
    try {
      const { jwt } = await this.$api.auth.login(credentials);
      this.$jwt.saveToken(jwt);
      this.$api.auth.setAuthHeader(jwt);
      commit(
        SET_ENTITY,
        { module, entity: Entity.IS_AUTHENTICATED, value: true },
        { root: true }
      );

      await dispatch("getMe", null);
      return true;
    } catch (e) {
      this.$notifier.error(IDENTITY_ERROR);
      return false;
    }
  },

  async logout({ commit, dispatch }, sendRequest = false) {
    if (sendRequest) {
      // await this.$api.auth.logout();
      // console.log(sendRequest);
    }
    this.$jwt.destroyToken();
    this.$api.auth.setAuthHeader(null);

    // await dispatch("sports/clear", null, { root: true });

    commit(
      SET_ENTITY,
      { module, entity: Entity.IS_AUTHENTICATED, value: false },
      { root: true }
    );

    commit(
      SET_ENTITY,
      { module, entity: Entity.USER, value: null },
      { root: true }
    );
  },

  async setMe({ commit }, data) {
    const userData = {
      lastname: data.lastname,
      firstname: data.firstname,
      email: data.email,
      avatar: data.avatar || null,
      username: data.username,
      position: data.position || null,
      category_sport_type: data.category_sport_type || null,
      isEditor: data.role.name === "CspEditor",
      isManager: data.isManager,
      sportType: data.category_sport_type?.id || null,
    };
    commit(
      SET_ENTITY,
      { module, entity: Entity.USER, value: userData },
      { root: true }
    );
  },

  async getMe({ commit, dispatch }, data = null) {
    try {
      if (!data) {
        data = await this.$api[Resource.AUTH].getMe();
      }
      dispatch("setMe", data);
      return true;
    } catch {
      await dispatch("logout", false);
      return false;
    }
  },
};
