export const InternationalFederationFormErrors = {
  siteUrl: "Не действительный адрес сайта",
};

export const IDENTITY_ERROR = "Неверный адрес или пароль";
export const NETWORK_ERROR =
  "Ошибка сети. Попробуйте позднее или обратитесь к администратору";

export const SAVE_SUCCESS = "Данные сохранены";
export const ADD_SUCCESS = "Данные добавлены";
export const ADD_ERROR = "Не удалось добавить данные";
export const ATHLETE_ADDED = "Спортсмен добавлен";
export const SPORT_EVENT_ADDED = "Спортивное событие добавлено";
export const SAVE_ERROR =
  "Не удалось сохранить данные. Обратитесь к администратору";

export const DELETE_SUCCESS = "Данные удалены";
export const DELETE_ERROR =
  "Не удалось удалить данные. Обратитесь к администратору";
export const DELETE_BLOCKED = "Удаление запрещено администратором";
export const DELETE_NOTICE = "Операция не обратима. Продолжить?";

export const DATE_DIFF_ERROR = "Неверный диапазон дат";

export const PUBLISH_SUCCESS = "Данные опубликованы";
export const PUBLISH_ERROR = "Не удалось опубликовать данные";
export const UNPUBLISHED_SUCCESS = "Данные скрыты";
export const UNPUBLISHED_ERROR = "Не удалось скрыть данные";
export const UNKNOWN_ERROR =
  "Возникла непредвиденная ошибка, пожалуйста обратитесь к администратору";

export const HIDE_NOTICE =
  "Возможны ошибки на сайте. Вы действительно хотите скрыть данные?";
export const HIDE_TITLE = "Скрытие данных";

export const CONFIRM_TEXT = "Да";
export const CANCEL_TEXT = "Нет";

export const COUNTRY_NOT_SELECTED = "Не выбрана страна";
