<template>
  <div>
    <AppNavbar />
    <AppBreadcrumb />
    <AppNotifications />
    <slot />
  </div>
</template>

<script>
import AppNavbar from "@/common/components/AppNavbar";
import { DEFAULT_TITLE } from "@/common/const/common";

export default {
  components: { AppNavbar },
  created() {
    document.title = DEFAULT_TITLE;
  },
};
</script>
