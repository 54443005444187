<template>
  <div v-if="notifications.length" class="notification__wrapper">
    <div
      v-for="{ text, type, id } in notifications"
      :key="id"
      :class="`notification notification--${type}`"
    >
      <!--      eslint-disable-next-line-->
      <p v-html="text" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AppNotifications",
  computed: {
    ...mapState({
      notifications: "notifications",
    }),
  },
  // watch: {
  //   notifications(newValue) {
  //     console.log(this.notifications);
  //
  //     newValue.forEach(({ text, type }) => {
  //       this.$buefy.notification.open({
  //         message: text,
  //         type: `is-${type}`,
  //       });
  //     });
  //   },
  // },
};
</script>

<style scoped>
.notification {
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  padding: 20px;

  text-align: center;

  color: #ffffff;
  border-bottom: 2px solid transparent;
}
.notification__wrapper {
  position: fixed;
  z-index: 9999;
  top: 60px;
  right: 0;

  width: 300px;
  padding: 10px;
}

.notification--info {
  border-color: #1e88e5;
  background: #b2ebf2;
}

.notification--error {
  border-color: #e53935;
  background: #e57373;
}

.notification--success {
  border-color: #38a413;
  background: #8cb97c;
}

.notification--warning {
  border-color: #ff9800;
  background: #ed6300;
}

.notification p {
  text-align: left;
}
</style>
