import Home from "@/views/Home";
import { AppRoute } from "@/common/const/route";

export const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: AppRoute.SPORTS,
    component: () => import("@/views/Sports.vue"),
    children: [
      {
        path: "",
        component: () => import("@/modules/sports/components/SportsIndex"),
        name: "SportsIndex",
        meta: {
          breadcrumbs: [
            { name: "SportsIndex", title: "Виды спорта", active: true },
          ],
        },
      },
      {
        path: `:id`,
        component: () => import("@/modules/sports/components/SportShow"),
        name: "SportShow",
        meta: {
          breadcrumbs: [
            { name: "SportsIndex", title: "Виды спорта" },
            { name: "SportShow", title: "", active: true },
          ],
        },
      },
    ],
  },
  {
    path: AppRoute.INTERNATIONAL_FEDERATIONS,
    component: () => import("@/views/InternationalFederations.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(
            "@/modules/internationFederations/components/InternationalFederationsIndex"
          ),
        name: "InternationalFederationsIndex",
        meta: {
          breadcrumbs: [
            {
              name: "InternationalFederationsIndex",
              title: "Международные федерации",
              active: true,
            },
          ],
        },
      },
      {
        path: `:id`,
        component: () =>
          import(
            "@/modules/internationFederations/components/InternationalFederationShow"
          ),
        name: "InternationalFederationShow",
        meta: {
          breadcrumbs: [
            {
              name: "InternationalFederationsIndex",
              title: "Международные федерации",
              active: false,
            },
            { name: "InternationalFederationShow", title: "", active: true },
          ],
        },
      },
    ],
  },
  {
    path: AppRoute.ATHLETES,
    component: () => import("@/views/Athletes"),
    children: [
      {
        path: "",
        component: () => import("@/modules/athletes/components/AthletesIndex"),
        name: "AthletesIndex",
        meta: {
          breadcrumbs: [
            {
              name: "AthletesIndex",
              title: "Спортсмены",
              active: true,
            },
          ],
        },
      },
      {
        path: "load-athletes",
        component: () => import("@/modules/athletes/components/AthletesLoad"),
        name: "AthletesLoad",
        meta: {
          breadcrumbs: [
            {
              name: "AthletesIndex",
              title: "Спортсмены",
              active: false,
            },
            { name: "AthletesLoad", title: "Загрузить из файла", active: true },
          ],
        },
      },
      {
        path: `:id`,
        component: () => import("@/modules/athletes/components/AthleteShow"),
        name: "AthleteShow",
        meta: {
          breadcrumbs: [
            {
              name: "AthletesIndex",
              title: "Спортсмены",
              active: false,
            },
            { name: "AthleteShow", title: "Спортсмен", active: true },
          ],
        },
      },
    ],
  },
  {
    path: AppRoute.CALENDAR,
    component: () => import("@/views/Calendar"),
    children: [
      {
        path: "",
        component: () =>
          import("@/modules/calendar/components/SportEventsIndex"),
        name: "CalendarIndex",
        meta: {
          breadcrumbs: [
            {
              name: "CalendarIndex",
              title: "Календарь",
              active: true,
            },
          ],
        },
      },
      {
        path: `:id`,
        component: () => import("@/modules/calendar/components/SportEventShow"),
        name: "SportEventShow",
        meta: {
          breadcrumbs: [
            {
              name: "CalendarIndex",
              title: "Календарь",
              active: false,
            },
            { name: "SportEventShow", title: "Событие", active: true },
          ],
        },
      },
    ],
  },
  {
    path: AppRoute.RANKS,
    component: () => import("@/views/Ranks"),
    children: [
      {
        path: "",
        component: () => import("@/modules/ranks/components/RanksIndex"),
        name: "RanksIndex",
        meta: {
          breadcrumbs: [
            {
              name: "RanksIndex",
              title: "Спортивные звания",
              active: true,
            },
          ],
        },
      },
    ],
  },
  {
    path: AppRoute.REGIONS,
    component: () => import("@/views/Regions"),
  },
  {
    path: AppRoute.USER_PROFILE,
    component: () => import("@/views/User"),
  },
  {
    path: AppRoute.LOGIN,
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: {
      layout: "auth-layout",
    },
  },
  {
    path: AppRoute.HELP,
    name: "Help",
    component: () => import("@/views/Help.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound"),
  },
];
