<template>
  <section class="section">
    <h1 class="title">Статистика</h1>

    <div class="content">
      <div class="columns is-multiline mt-4 mb-6">
        <div class="column">
          <h2 class="has-text-grey-dark is-size-5">
            Спортсменов всего:&nbsp;
            <router-link :to="AppRoute.ATHLETES" title="Перейти к спортсменам">
              {{ countAthletes }}
            </router-link>
          </h2>
        </div>

        <div v-if="chartPieData" class="column is-full">
          <AppPieChart :chart-data="chartPieData" />
        </div>
      </div>

      <div class="columns is-multiline my-6">
        <div class="column mt-5">
          <h2 class="has-text-grey-dark is-size-5">
            Календарь событий
            <b-select
              v-model="currentYear"
              size="is-medium"
              class="header-select"
            >
              <option
                v-for="year in years"
                :key="`year-${year}`"
                :value="year"
                :selected="year === currentYear"
              >
                {{ year }}
              </option> </b-select
            >&nbsp;год:&nbsp;
            <router-link :to="AppRoute.CALENDAR" title="Перейти в календарь">
              {{ countEvents }}
            </router-link>
          </h2>
        </div>

        <div v-if="chartBarData" class="column is-full">
          <AppBarChart :chart-data="chartBarData" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Resource } from "@/common/const/common";
import { mapGetters } from "vuex";
import { getRandomRGB } from "@/common/utils/color";
import AppPieChart from "@/common/components/AppPieChart";
import { Month } from "@/common/const/month";
import AppBarChart from "@/common/components/AppBarChart";
import { numberRange } from "@/common/utils/common";
import { AppRoute } from "@/common/const/route";

export default {
  name: "Home",

  components: { AppBarChart, AppPieChart },

  data() {
    return {
      categorySport: "category_sport_null=false",
      countAthletes: 0,
      countAthletesWithoutPhoto: 0,
      countEvents: 0,
      chartPieData: null,
      chartBarData: null,
      currentYear: 2022,
      startYear: 2021,
      years: [],
      AppRoute,
    };
  },

  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
      user: "auth/getUser",
    }),
  },

  watch: {
    async currentYear() {
      await this.loadEventsData();
    },
  },

  created() {
    document.title = "ЦСП Редактор. Главная страница.";
    this.categorySport = this.isEditor
      ? `category_sport.category_sport_type=${this.user.sportType}`
      : `category_sport_null=false`;
    this.currentYear = new Date().getFullYear();
    this.years = numberRange(this.startYear, this.currentYear);
  },

  async mounted() {
    try {
      await this.loadAthletesData();
      await this.loadEventsData();
    } catch (e) {
      console.log(e);
    }
  },

  methods: {
    async loadAthletesData() {
      const data = await this.$api[Resource.PEOPLES].get("find-count-athletes");

      this.countAthletes = data.reduce((acc, { count }) => acc + count, 0);

      this.chartPieData = {
        labels: data.map((item) => item.title),
        datasets: [
          {
            backgroundColor: data.map(() => getRandomRGB()),
            data: data.map((item) => item.count),
          },
        ],
      };
    },

    async loadEventsData() {
      const data = await this.$api[Resource.SPORT_EVENTS].get(
        `find-count-by-month/${this.currentYear}`
      );

      this.countEvents = data.reduce((acc, { count }) => acc + count, 0);

      this.chartBarData = {
        labels: data.map(({ _id: { month } }) => Month[month]),
        datasets: [
          {
            label: "",
            backgroundColor: data.map(() => getRandomRGB()),
            data: data.map(({ count }) => count),
          },
        ],
      };
    },
  },
};
</script>

<style scoped>
.header-select {
  display: inline-block;
  line-height: 1.5;
  vertical-align: middle;
}
</style>
