<template>
  <b-navbar class="container navbar">
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: AppRoute.MAIN }">
        <img src="@/assets/img/logo.png" alt="Logo" style="max-height: 60px" />
      </b-navbar-item>
    </template>

    <template #start>
      <b-navbar-item tag="router-link" :to="{ path: AppRoute.MAIN }">
        Главная
      </b-navbar-item>

      <b-navbar-item tag="router-link" :to="{ path: AppRoute.CALENDAR }">
        Календарь
      </b-navbar-item>

      <b-navbar-item tag="router-link" :to="{ path: AppRoute.ATHLETES }">
        Спортсмены
      </b-navbar-item>

      <b-navbar-item
        v-if="isEditor"
        tag="router-link"
        :to="{ path: AppRoute.SPORTS }"
      >
        Виды спорта
      </b-navbar-item>

      <b-navbar-dropdown
        v-if="isEditor"
        label="Дополнительно"
        hoverable
        collapsible
      >
        <b-navbar-item
          tag="router-link"
          :to="{ path: AppRoute.INTERNATIONAL_FEDERATIONS }"
        >
          Международные федерации
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: AppRoute.RANKS }">
          Спортивные звания
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: AppRoute.REGIONS }">
          Регионы РФ
        </b-navbar-item>
      </b-navbar-dropdown>

      <b-navbar-item tag="router-link" :to="{ path: AppRoute.HELP }">
        Справка
      </b-navbar-item>
    </template>

    <template #end>
      <!--      <b-navbar-item v-if="avatar" class="pr-0">-->
      <!--        <div class="avatar" :style="avatar" />-->
      <!--      </b-navbar-item>-->
      <b-navbar-dropdown hoverable collapsible>
        <template #label>
          <div v-if="avatar" class="avatar" :style="avatar" />
          <span>{{ userName }}</span>
        </template>
        <b-navbar-item tag="router-link" :to="{ path: AppRoute.USER_PROFILE }">
          Профиль
        </b-navbar-item>
        <b-navbar-item @click="logout"> Выход </b-navbar-item>
      </b-navbar-dropdown>
    </template>
  </b-navbar>
</template>

<script>
import { AppRoute } from "../const/route";
import { mapActions, mapGetters } from "vuex";
import { getThumbUrl } from "@/common/utils/common";

export default {
  name: "AppNavbar",

  data() {
    return {
      AppRoute,
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser",
      isEditor: "auth/isEditor",
    }),

    avatar() {
      if (!this.user?.avatar) {
        return null;
      }
      const url = getThumbUrl(this.user.avatar);
      return `background-image: url(${url})`;
    },

    userName() {
      if (!this.user) {
        return "";
      }
      const firstname = this.user.firstname || "";
      const lastname = this.user.lastname || "";
      return `${firstname} ${lastname}`;
    },
  },

  methods: {
    ...mapActions({
      authLogout: "auth/logout",
    }),

    async logout() {
      await this.authLogout();
      await this.$router.push(AppRoute.LOGIN);
    },
  },
};
</script>

<style scoped>
.avatar {
  display: inline-block;
  margin-right: 0.8rem;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-position: 50% 50%;
  background-size: cover;
}
</style>
