export const AppRoute = {
  MAIN: "/",
  LOGIN: "/login",
  ABOUT: "/about",
  SPORTS: "/sports",
  INTERNATIONAL_FEDERATIONS: "/international-federations",
  ATHLETES: "/athletes",
  CALENDAR: "/calendar",
  RANKS: "/ranks",
  REGIONS: "/regions",
  USER_PROFILE: "/profile",
  HELP: "/help",
  NOT_FOUND: "/*",
};

export const ApiRoute = {
  LOGOUT: "/logout",
  LOGIN: "/auth/local",
  // GET_ME: "/users/me",
  GET_ME: "/csp-users/me",
  UPDATE_ME: "/csp-users/update",
  CHANGE_PASSWORD: "/csp-users/change-password",
  DELETE_AVATAR: "/csp-users/delete-avatar",
  UPDATE_AVATAR: "/csp-users/update-avatar",
};
