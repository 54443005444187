import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { routes } from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.name !== "Login" && !store.getters["auth/getUser"]) {
    const isAuth = await store.dispatch("auth/setAuth");

    if (!isAuth) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else if (to.name === "Login" && store.getters["auth/getUser"]) {
    next({ name: "Home" });
  } else {
    next();
  }
});

export default router;
