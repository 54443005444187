<template>
  <div class="container">
    <div class="columns is-mobile is-centered">
      <div class="column is-one-third-desktop">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { DEFAULT_TITLE } from "../common/const/common";

export default {
  name: "AuthLayout",
  created() {
    document.title = DEFAULT_TITLE;
  },
};
</script>
