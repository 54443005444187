import { Entity } from "./const";

export default {
  getUser: (state) => {
    return state[Entity.USER];
  },
  isEditor: (state) => {
    return state[Entity.USER]?.isEditor || false;
  },
  isWinter: (state) => {
    return state[Entity.USER]?.category_sport_type?.Name.includes(
      "Зимние олимпийские виды"
    );
  },
  isSummer: (state) => {
    return state[Entity.USER]?.category_sport_type?.Name.includes(
      "Летние олимпийские виды"
    );
  },
  isReader: (state) => {
    return state[Entity.USER]?.category_sport_type === null;
  },
  getUserSportType: (state) => {
    return state[Entity.USER].sportType || null;
  },
};
