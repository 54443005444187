<template>
  <div id="app">
    <AppNotifications />
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
export default {
  computed: {
    layout() {
      return this.$route.meta.layout || "default-layout";
    },
  },
};
</script>

<style>
@import "./assets/css/style.css";
</style>
