<template>
  <section class="section mb-0 mt-1 pb-0 pt-0">
    <div class="container">
      <b-breadcrumb v-if="links.length > 1" size="is-small">
        <b-breadcrumb-item
          v-for="link in links"
          :key="link.name"
          tag="router-link"
          :to="{ name: link.name }"
          :active="link.active || false"
        >
          {{ link.title }}
        </b-breadcrumb-item>
      </b-breadcrumb>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppBreadcrumb",
  data() {
    return {
      links: [{ name: "Home", title: "Главная" }],
    };
  },
  watch: {
    $route(to) {
      this.links = this.links.slice(0, 1);

      if (to.meta?.breadcrumbs) {
        to.meta.breadcrumbs.forEach((link) => this.links.push(link));
      }
    },
  },
};
</script>
