import Vue from "vue";

import VueMarkdownEditor from "@kangc/v-md-editor";
import enUS from "@kangc/v-md-editor/lib/lang/en-US";
import "@kangc/v-md-editor/lib/style/base-editor.css";

import "@kangc/v-md-editor/lib/theme/style/github.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import VMdPreview from "@kangc/v-md-editor/lib/preview";

VueMarkdownEditor.use(githubTheme);
VueMarkdownEditor.lang.use("en-US", enUS);
Vue.use(VueMarkdownEditor);

VMdPreview.use(githubTheme);
Vue.use(VMdPreview);

/*
import enUS from '@kangc/v-md-editor/lib/lang/en-US';

VueMarkdownEditor.lang.use('en-US', enUS);
 */
