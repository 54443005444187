import Vue from "vue";

import DefaultLayout from "@/layouts/AppLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import AppNotifications from "@/common/components/AppNotifications";
import AppBreadcrumb from "@/common/components/AppBreadcrumb";

Vue.component("default-layout", DefaultLayout);
Vue.component("auth-layout", AuthLayout);
Vue.component("AppNotifications", AppNotifications);
Vue.component("AppBreadcrumb", AppBreadcrumb);
