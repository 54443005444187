import {
  AuthApiService,
  ReadApiService,
  CrudApiService,
} from "../../service/api";
import { Resource } from "../const/common";

export default (notifier) => {
  return {
    fetchData: new ReadApiService(notifier),
    [Resource.AUTH]: new AuthApiService(notifier),
    [Resource.SPORTS]: new CrudApiService(Resource.SPORTS, notifier),
    [Resource.INTERNATIONAL_FEDERATIONS]: new CrudApiService(
      Resource.INTERNATIONAL_FEDERATIONS,
      notifier
    ),
    [Resource.SPORT_DISCIPLINES]: new CrudApiService(
      Resource.SPORT_DISCIPLINES,
      notifier
    ),
    [Resource.PEOPLES]: new CrudApiService(Resource.PEOPLES, notifier),
    [Resource.RANKS]: new CrudApiService(Resource.RANKS, notifier),
    [Resource.UPLOAD]: new CrudApiService(Resource.UPLOAD, notifier),
    [Resource.SPORT_EVENTS]: new CrudApiService(
      Resource.SPORT_EVENTS,
      notifier
    ),
    [Resource.SPORT_RESULTS]: new CrudApiService(
      Resource.SPORT_RESULTS,
      notifier
    ),
    [Resource.MEDAL_STANDING]: new CrudApiService(
      Resource.MEDAL_STANDING,
      notifier
    ),
    [Resource.UPLOAD]: new CrudApiService(Resource.UPLOAD, notifier),
  };
};
