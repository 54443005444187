import auth from "./auth";
// import federations from "@/modules/internationFederations/store";
// import sports from "@/modules/sports/store";
// import ranks from "@/modules/ranks/store";

export default {
  auth,
  // federations,
  // sports,
  // ranks,
};
