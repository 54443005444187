export const TOKEN_KEY = "token";

export const Message = {
  SERVER_ERROR: "Ошибка сервера",
};

export const NotificationTypes = {
  INFO: "info",
  SUCCESS: "success",
  ERROR: "error",
  // ERROR: "danger",
  WARNING: "warning",
};

export const Resource = {
  AUTH: "auth",
  SPORTS: "category-sports",
  INTERNATIONAL_FEDERATIONS: "international-federations",
  SPORT_DISCIPLINES: "sport-disciplines",
  PEOPLES: "peoples",
  RANKS: "ranks",
  CATEGORY_PEOPLE: "category-people",
  REGIONS: "regions-russia",
  UPLOAD: "upload/files",
  SPORT_EVENTS: "events",
  SPORT_RESULTS: "sport-results",
  COUNTRIES: "countries",
  MEDAL_STANDING: "medal-standings",
};

export const MESSAGE_LIVE_TIME = 3000;
export const LOCALE = "ru-RU";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_PRINT_FORMAT = "DD.MM.YYYY";
export const DEFAULT_TITLE =
  "Центр спортивной подготовки сборных команд России";

export const GENDERS = ["Мужчины", "Женщины", "Юниоры", "Юниорки"];
