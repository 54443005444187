export const getThumbUrl = (photo) => {
  if (!photo) {
    return null;
  }
  const { formats, url } = photo;
  const uri = formats?.thumb?.url || formats?.small?.url || url;
  return process.env.VUE_APP_API_URL + uri;
};
export const getImgUrl = ({ url }) => {
  return process.env.VUE_APP_API_URL + url;
};

export const numberRange = (start, end) => {
  return new Array(end + 1 - start).fill().map((d, i) => i + start);
};
