import {
  AuthApiService,
  ReadApiService,
  CrudApiService,
} from "../../service/api";
import { Resource } from "../const/common";

export default (notifier) => {
  return {
    fetchData: new ReadApiService(notifier),
    [Resource.AUTH]: new AuthApiService(notifier),
    [Resource.SPORTS]: new CrudApiService(Resource.SPORTS, notifier),
  };
};
