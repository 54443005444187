import axios from "axios";
import { isObject } from "lodash";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (e) => {
    // console.log(e.response.data.message);

    try {
      const { errors } = e?.response?.data;
      if (isObject(errors)) {
        const message = Object.keys(errors).join(", ");
        axiosInstance.$notifier.error(`Ошибки в полях ${message}`);
      }
    } catch (e) {
      // console.log(e);
    }

    // try {
    //   const { message } = e.response.data;
    //   axiosInstance.$notifier.error(message);
    // } catch (e) {
    //   console.log(e);
    // }

    return Promise.reject(e);
  }
);

export default axiosInstance;
